body {
  margin: 0;
  padding: 0;
}
.App {
  position: relative;
  min-height: 100vh;
}

.whatsapp-button {
  position: fixed;
  bottom: 24px;
  right: 24px;
  background-color: #25D366;  /* WhatsApp brand green */
  padding: 12px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-button:hover {
  background-color: #128C7E;  /* Darker green on hover */
  transform: scale(1.1);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.whatsapp-icon {
  width: 32px;
  height: 32px;
  color: white;
}