@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@400;500;600;700&display=swap');

.accommodation {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(135deg, #fff8f0, #fff1e6);
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

/* Decorative Elements */
.decorative-shape {
  position: absolute;
  border-radius: 50%;
  opacity: 0.1;
  pointer-events: none;
}

.shape-1 {
  top: -100px;
  left: -100px;
  width: 400px;
  height: 400px;
  background: linear-gradient(45deg, #b17f55, #8b5e34);
  animation: float 15s infinite alternate;
}

.shape-2 {
  bottom: 20%;
  right: -150px;
  width: 300px;
  height: 300px;
  background: linear-gradient(-45deg, #8b5e34, #b17f55);
  animation: float 20s infinite alternate-reverse;
}

.shape-3 {
  top: 40%;
  left: 10%;
  width: 200px;
  height: 200px;
  background: linear-gradient(90deg, #b17f55, #8b5e34);
  animation: float 18s infinite alternate;
}

/* Header Styles */
.header-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  position: relative;
}

.main-title {
  font-family: 'Cormorant', serif;
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 1.2;
  color: #8b5e34;
  margin: 0;
  opacity: 0;
  animation: slideUp 1s forwards 0.5s;
}

.title-line {
  display: block;
}

.title-line.accent {
  color: #b17f55;
  font-style: italic;
}

.subheading {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #b17f55;
  margin-bottom: 1rem;
  opacity: 0;
  animation: slideUp 1s forwards 0.3s;
}

.subtitle {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.25rem;
  color: #666;
  margin-top: 1rem;
  opacity: 0;
  animation: slideUp 1s forwards 0.7s;
}

/* Amenities Section */
.amenities-section {
  padding: 6rem 2rem;
}

.section-header {
  text-align: center;
  margin-bottom: 4rem;
}

.section-title {
  font-family: 'Cormorant', serif;
  font-size: 3rem;
  color: #8b5e34;
  margin-bottom: 1rem;
}

.title-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.separator-line {
  width: 60px;
  height: 2px;
  background: #b17f55;
}

.separator-icon {
  color: #b17f55;
  font-size: 1.5rem;
}

.amenities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.amenity-card {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2.5rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
  transform: translateY(50px);
  opacity: 0;
  animation: slideUpFade 1s forwards;
  transition: all 0.4s ease;
  position: relative;
}

.amenity-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
}

.amenity-header {
  margin-bottom: 2rem;
}

.icon-wrapper {
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, #b17f55, #8b5e34);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.icon-wrapper svg {
  color: white;
  font-size: 1.8rem;
}

.amenity-card h3 {
  font-family: 'Cormorant', serif;
  font-size: 1.6rem;
  color: #8b5e34;
  margin: 0;
}

.amenity-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.amenity-item {
  padding: 0.75rem 0;
  color: #666;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.amenity-item:hover {
  transform: translateX(10px);
  color: #8b5e34;
}

.bullet {
  width: 8px;
  height: 8px;
  background: #b17f55;
  border-radius: 50%;
  margin-right: 1rem;
  transition: all 0.3s ease;
}

.amenity-item:hover .bullet {
  transform: scale(1.5);
  background: #8b5e34;
}

/* Experience Section */
.experience-section {
  padding: 6rem 2rem;
  background: white;
}

.experience-content {
  max-width: 1400px;
  margin: 0 auto;
}

.experience-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  margin-top: 4rem;
}

.experience-image-container {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.experience-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
}

.experience-card:hover .experience-image {
  transform: scale(1.1);
}

.experience-card {
  position: relative;
  text-align: center;
  padding: 2.5rem;
  border-radius: 20px;
  background: linear-gradient(135deg, #fff8f0, #fff1e6);
  transition: all 0.3s ease;
}

.experience-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(139, 94, 52, 0.1);
}

.experience-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 1.5rem;
  background: linear-gradient(135deg, #b17f55, #8b5e34);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
}

.experience-card h3 {
  font-family: 'Cormorant', serif;
  font-size: 1.7rem;
  color: #8b5e34;
  margin: 0 0 1rem 0;
}

.experience-card p {
  color: #666;
  line-height: 1.6;
}

/* Animations */
@keyframes float {
  0% { transform: translate(0, 0) rotate(0deg); }
  100% { transform: translate(20px, 20px) rotate(360deg); }
}

@keyframes slideUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUpFade {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 1200px) {
  .main-title {
    font-size: 3.8rem;
  }
  
  .amenities-grid,
  .experience-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .experience-image-container {
    height: 220px;
  }
}

@media (max-width: 768px) {
  .main-title {
    font-size: 2.8rem;
  }

  .subtitle {
    font-size: 1.1rem;
  }

  .amenities-grid,
  .experience-grid {
    grid-template-columns: 1fr;
  }

  .section-title {
    font-size: 2.5rem;
  }

  .amenity-card {
    padding: 2rem;
  }

  .experience-image-container {
    height: 200px;
  }

  .experience-card {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .main-title {
    font-size: 2.4rem;
  }

  .experience-card {
    padding: 1.5rem;
  }

  .experience-image-container {
    height: 180px;
  }
}