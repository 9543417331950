.footer {
  position: relative;
  background: linear-gradient(135deg, #78350f, #92400e);
  color: #fff;
  overflow: hidden;
}

.footer-background {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.footer-shape {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  filter: blur(60px);
}

.shape-1 {
  width: 400px;
  height: 400px;
  top: -200px;
  left: -200px;
}

.shape-2 {
  width: 300px;
  height: 300px;
  bottom: -150px;
  right: -150px;
}

.footer-content {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem 2rem;
}

.footer-sections {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-bottom: 3rem;
}

.footer-section h3 {
  font-family: 'Cormorant', serif;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #fff;
}

.footer-section h4 {
  font-family: 'Cormorant', serif;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  color: #fff;
}

.about-text {
  margin-bottom: 1.5rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: #fff;
  transition: all 0.3s ease;
}

.social-link:hover {
  background: #fff;
  color: #92400e;
  transform: translateY(-3px);
}

.quick-links {
  list-style: none;
  padding: 0;
}

.quick-links li {
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quick-links a {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  transition: all 0.3s ease;
}

.quick-links a:hover {
  color: #fff;
  transform: translateX(5px);
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: rgba(255, 255, 255, 0.9);
}

.contact-item svg {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.contact-item p {
  font-size: 0.9rem;
  line-height: 1.4;
}

.footer-divider {
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: 2rem;
}

.footer-bottom {
  text-align: center;
}

.copyright {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .footer-sections {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .footer-content {
    padding: 4rem 2rem 2rem;
  }

  .footer-section h3 {
    font-size: 1.7rem;
  }

  .footer-section h4 {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .footer-content {
    padding: 3rem 1.5rem 1.5rem;
  }

  .footer-sections {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .footer-section h3 {
    font-size: 1.6rem;
    text-align: center;
  }

  .footer-section h4 {
    font-size: 1.5rem;
    text-align: center;
  }

  .about-text {
    text-align: center;
  }

  .social-links {
    justify-content: center;
  }

  /* .quick-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
   .links{
    display: flex;
    flex-direction: column;
    align-items: center;
   }

  .contact-info {
    align-items: center;
  }

  .contact-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contact-item svg {
    margin-bottom: 0.4rem;
    margin-top: 0;
  }

  .contact-item p {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .footer-content {
    padding: 2rem 1rem 1rem;
  }

  .footer-section h3 {
    font-size: 1.5rem;
  }

  .footer-section h4 {
    font-size: 1.4rem;
  }

  .social-links {
    gap: 0.5rem;
  }

  .social-link {
    width: 35px;
    height: 35px;
  }

  .contact-info {
    gap: 1rem;
  }

  .contact-item svg {
    width: 20px;
    height: 20px;
  }
  .contact-item{
    flex-direction: row;
   }

  .contact-item p {
    font-size: 0.8rem;
  }

  .copyright {
    font-size: 0.8rem;
  }
}

@media (max-width: 320px) {
  .footer-content {
    padding: 1.5rem 0.5rem 0.5rem;
  }

  .footer-section h3 {
    font-size: 1.4rem;
  }

  .footer-section h4 {
    font-size: 1.3rem;
  }

  .social-link {
    width: 30px;
    height: 30px;
  }

  .contact-info {
    gap: 0.8rem;
  }
  .contact-item{
    flex-direction: row;
   }
  .contact-item svg {
    width: 18px;
    height: 18px;
  }

  .contact-item p {
    font-size: 0.75rem;
  }
}