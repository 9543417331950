@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

:root {
    --primary-color: #b45309;
    --secondary-color: #78350f;
    --text-color: #525252;
    --background-gradient: linear-gradient(135deg, #fff8f0, #fff1e6);
    --white-translucent: rgba(255, 255, 255, 0.8);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
}

.overview {
    min-height: 100vh;
    background: var(--background-gradient);
    position: relative;
    overflow: hidden;
    padding: 6rem 0;
    margin: 2rem 0 0 0;
}

.background-shape {
    position: absolute;
    width: 24rem;
    height: 24rem;
    border-radius: 50%;
    background: linear-gradient(135deg, rgba(180, 83, 9, 0.1), rgba(146, 64, 14, 0.1));
    filter: blur(24px);
    z-index: 0;
}

.shape-left {
    top: 0;
    left: -6rem;
}

.shape-right {
    bottom: 0;
    right: -6rem;
}

.overview-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 1.5rem;
    position: relative;
    z-index: 1;
}

.overview-header {
    text-align: center;
    margin-bottom: 4rem;
}

.overview-header h2 {
    font-family: 'Cormorant', serif;
    font-size: 2.5rem;
    color: var(--secondary-color);
    margin-bottom: 1.5rem;
    font-weight: 900;
}

.header-divider, .content-divider {
    display: flex;
    justify-content: center;
    align-items: center;
}

.divider-content {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.divider-line {
    height: 1px;
    width: 4rem;
    background-color: var(--primary-color);
}

.divider-icon {
    color: var(--primary-color);
    width: 1.5rem;
    height: 1.5rem;
}

.overview-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    align-items: stretch;
}

.left-column {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.right-column {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.image-carousel {
    position: relative;
    width: 100%;
    height: 400px;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.carousel-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.carousel-image.active {
    opacity: 1;
}

.video-section {
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
}

.video-container {
    position: relative;
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 0.75rem;
    overflow: hidden;
}

.homestay-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-controls {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    gap: 0.5rem;
    z-index: 2;
}

.video-control-btn {
    background: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    padding: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.video-control-btn:hover {
    background: rgba(255, 255, 255, 1);
    transform: scale(1.05);
}

.video-control-btn:active {
    transform: scale(0.95);
}

.contact-card {
    background: var(--white-translucent);
    backdrop-filter: blur(12px);
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-card h3 {
    font-family: 'Cormorant', serif;
    color: var(--secondary-color);
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.contact-card p {
    color: var(--text-color);
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.contact-actions {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.contact-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    background-color: #25D366;
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-btn:hover {
    background-color: #128C7E;
}

.contact-details {
    background-color: rgba(180, 83, 9, 0.05);
    padding: 1rem;
    border-radius: 0.5rem;
}

.contact-details p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    color: var(--secondary-color);
}

.content-card {
    background: var(--white-translucent);
    backdrop-filter: blur(12px);
    border-radius: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.card-inner {
    padding: 2rem;
}

.description-text {
    font-size: 1.125rem;
    line-height: 1.8;
    color: var(--text-color);
    margin: 0.5rem 0;
}

.closing-text {
    font-family: 'Cormorant', serif;
    font-size: 1.5rem;
    color: var(--secondary-color);
    text-align: center;
    margin-top: 2rem;
}

/* Responsive Designs */
@media (max-width: 1200px) {
    .overview-content {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

@media (max-width: 992px) {
    .overview-content {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .image-carousel {
        height: 350px;
    }

    .video-controls {
        bottom: 1.5rem;
        right: 1.5rem;
    }
}

@media (max-width: 576px) {
    .image-carousel {
        height: 300px;
    }

    .contact-card {
        padding: 1.5rem;
    }

    .video-controls {
        bottom: 1rem;
        right: 1rem;
    }

    .video-control-btn {
        padding: 10px;
    }
}