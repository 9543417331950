:root {
    --primary-color: #b45309;
    --secondary-color: #78350f;
    --accent-color: #78350f;
    --text-primary: #1f2937;
    --text-secondary: #374151;
    --background-gradient-start: #fff8f0;
    --background-gradient-end: #fff1e6;
}

.testimonials-section {
    background: linear-gradient(135deg, var(--background-gradient-start), var(--background-gradient-end));
    padding: 4rem 2rem;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

.testimonials-container {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
}

.section-title {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    font-weight: bold;
    background: linear-gradient(to right, var(--primary-color), var(--accent-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.testimonials-wrapper {
    position: relative;
    overflow: hidden;
    padding: 1rem 0;
    -webkit-overflow-scrolling: touch;
}

.testimonials-track {
    display: flex;
    will-change: transform;
    backface-visibility: hidden;
    transform-style: preserve-3d;
}

.testimonial-card {
    width: 400px;
    background: white;
    border-radius: 1rem;
    padding: 2rem;
    margin: 0 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

.quote-icon {
    color: var(--primary-color);
    opacity: 0.7;
    margin-bottom: 1rem;
}

.testimonial-text {
    color: var(--text-primary);
    margin-bottom: 1.5rem;
    line-height: 1.6;
    font-weight: 500;
    flex-grow: 1;
    min-height: 120px;
}

.testimonial-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
}

.rating {
    display: flex;
    gap: 0.25rem;
}

.star-icon {
    color: var(--accent-color);
}

.testimonial-name {
    color: var(--text-secondary);
    font-weight: 600;
    letter-spacing: 0.5px;
}

@media (max-width: 768px) {
    .testimonial-card {
        width: 300px;
        padding: 1.5rem;
    }

    .section-title {
        font-size: 2rem;
    }

    .testimonials-section {
        padding: 3rem 1rem;
    }
}

@media (max-width: 480px) {
    .testimonial-card {
        width: 260px;
        padding: 1.25rem;
        margin: 0 0.75rem;
    }

    .section-title {
        font-size: 1.75rem;
        margin-bottom: 2rem;
    }

    .testimonials-section {
        padding: 2rem 0.5rem;
    }

    .testimonial-text {
        min-height: 140px;
    }
}