@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carattere&display=swap');
html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

.header {
  width: 100%;
  background: white;
  box-shadow: 0 2px 10px rgba(167, 126, 97, 0.1);
  font-family: 'Cormorant', serif;
  z-index: 1000;
  position: fixed;
  top: 0;
  transition: all 0.3s ease;
  font-size: 1.1rem; /* Base font size */
}

.header.scrolled {
  box-shadow: 0 4px 15px rgba(167, 126, 97, 0.15);
  padding: 0.5rem 0;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logo {
  height: 65px;
  width: auto;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.brand-name {
  font-family: 'Carattere', cursive;
  font-size: 2.5rem;
  color: #a07150;
}

.logo:hover {
  transform: scale(1.02);
}

.desktop-nav {
  display: none;
  gap: 2.5rem;
  align-items: center;
}

.nav-link {
  color: #a07150;
  text-decoration: none;
  font-size: 1.2rem; /* Increased font size */
  font-weight: 600; /* Stronger weight */
  padding: 0.25rem 0;
  position: relative;
  transition: color 0.3s ease;
  font-family: 'Cormorant', serif;
}

.nav-link:hover {
  color: #407663;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #a77e61, #407663);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.book-now-btn {
  background-color: #407663;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1.2rem; /* Larger button text */
  font-weight: 950; /* Stronger font weight for emphasis */
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(64, 118, 99, 0.2);
  font-family: 'Cormorant', serif;
}

.book-now-btn:hover {
  background-color: #2c5a47;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(64, 118, 99, 0.3);
}

.btn-icon {
  transition: transform 0.3s ease;
}

.book-now-btn:hover .btn-icon {
  transform: translateX(3px);
}

.mobile-menu-button {
  display: flex;
  padding: 0.5rem;
  color: #a77e61;
  transition: color 0.3s ease;
  border: none;
  background: none;
  cursor: pointer;
  font-family: 'Cormorant', serif;
}

.mobile-menu-button:hover {
  color: #407663;
}

.mobile-nav {
  display: none;
  flex-direction: column;
  background: white;
  border-top: 1px solid rgba(167, 126, 97, 0.1);
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
}

.mobile-nav.open {
  display: flex;
}

.mobile-nav-link {
  padding: 0.75rem 1rem;
  color: #a07150;
  text-decoration: none;
  font-size: 1.2rem; /* Larger font size for mobile links */
  font-weight: 600; /* Stronger font weight */
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(167, 126, 97, 0.1);
  font-family: 'Cormorant', serif;
}

.mobile-nav-link:hover {
  color: #407663;
  background: rgba(64, 118, 99, 0.05);
}

.mobile-book-now-btn {
  padding: 0.75rem 1rem;
  background-color: #407663;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 1.1rem; /* Larger mobile button text */
  font-weight: 700; /* Stronger font weight */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: background-color 0.3s ease;
  font-family: 'Cormorant', serif;
}

.mobile-book-now-btn:hover {
  background-color: #2c5a47;
}

.btn-icon {
  margin-left: 0.25rem;
}

@media (min-width: 768px) {
  .desktop-nav {
    display: flex;
  }

  .mobile-menu-button,
  .mobile-nav {
    display: none;
  }

  .header-container {
    padding: 1rem;
  }

  .nav-link {
    font-size: 1.3rem; /* Larger links for larger screens */
  }

  .brand-name {
    font-size: 2.2rem; /* Larger brand name for desktops */
  }
}

@media (max-width: 767px) {
  .header-container {
    padding: 0.5rem 1rem;
  }

  .brand-name {
    font-size: 1.7rem; /* Adjusted size for mobile branding */
  }

  .logo {
    height: 55px; /* Slightly larger logo for mobile */
  }
}
