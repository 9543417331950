
/* Location Section Styles */
.location-section {
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #fff8f0, #fff1e6);
  position: relative;
}
.location-container {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  padding: 2rem 0;
}
.map-container {
  height: 600px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.map-container:hover {
  transform: translateY(-10px);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.15);
}
.location-details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.address-card {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
  text-align: center;
}
.location-icon {
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, #b17f55, #8b5e34);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
  color: white;
  font-size: 1.5rem;
}
.address-card h3 {
  font-family: 'Cormorant', serif;
  font-size: 2rem;
  color: #8b5e34;
  margin: 0 0 1rem 0;
}
.address-card p {
  color: #666;
  line-height: 1.6;
  margin: 0.5rem 0;
}
.directions-container {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
}
.directions-container h3 {
  font-family: 'Cormorant', serif;
  font-size: 2rem;
  color: #8b5e34;
  margin: 0 0 2rem 0;
  text-align: center;
}
.transport-option {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem 0;
  border-bottom: 1px solid rgba(177, 127, 85, 0.1);
}
.transport-option:last-child {
  border-bottom: none;
}
.transport-icon {
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, #b17f55, #8b5e34);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.25rem;
  flex-shrink: 0;
}
.transport-details {
  flex-grow: 1;
}
.transport-details h4 {
  font-family: 'Cormorant', serif;
  font-size: 1.5rem;
  color: #8b5e34;
  margin: 0 0 0.5rem 0;
}
.transport-details p {
  color: #666;
  line-height: 1.6;
  margin: 0.25rem 0;
}
/* Responsive Design */
@media (max-width: 1200px) {
  .location-container {
    grid-template-columns: 1fr;
  }
  .map-container {
    height: 500px;
  }
}
@media (max-width: 768px) {
  .location-section {
    padding: 4rem 1.5rem;
  }
  .map-container {
    height: 400px;
  }
  .address-card,
  .directions-container {
    padding: 2rem;
  }
}
@media (max-width: 480px) {
  .location-section {
    padding: 3rem 1rem;
  }
  .map-container {
    height: 300px;
  }
  .transport-option {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }
  .transport-icon {
    margin: 0 auto;
  }
}